module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":680},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"fathom.kn8.lt","siteId":"AVKGY"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
